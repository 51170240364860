import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../util/reactIntl';
import { Modal, ResponsiveImage, DummyListingImageIcon } from '../../components';

import ImageCarousel from './ImageCarousel/ImageCarousel';
import ActionBarMaybe from './ActionBarMaybe';

import css from './ListingPage.module.css';

const renderImage = (image, cssClass = null) => {
  return (
    <div className={css.imageWrapper}>
      {image.id ? (
        <ResponsiveImage
          rootClassName={classNames(css.sectionHeaderImage, cssClass)}
          alt="listing image"
          image={image}
          variants={[
            'listing-card',
            'listing-card-2x',
            'listing-card-4x',
            'listing-card-6x'
          ]}
        />
      ) : (
        <div className={css.sectionHeaderNoImage}>
          {image.component}
        </div>
      )}
    </div>
  )
}

const getImage = (images, index) => images[index] ?? null;

const SectionHero = props => {
  const {
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
  } = props;

  const hasImages = listing?.images?.length > 0;
  const minMultipleImages = 5;
  const listingImages = listing.images;
  const listingImagesWithDummy = listingImages.length < minMultipleImages
    ? [
        ...listingImages,
        ...Array.from({ length: minMultipleImages - (listingImages.length) }, (value) => value)
          .map((_, ) => ({ component: <DummyListingImageIcon /> }))
      ]
    : listingImages;

  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe
        className={css.actionBarForHeroLayout}
        isOwnListing={isOwnListing}
        listing={listing}
        editParams={editParams}
      />
    </div>
  ) : null;

  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;

  return (
    <div className={css.sectionHeaderImages} data-testid="hero">
      <div onClick={handleViewPhotosClick} className={css.sectionHeaderImagesWrapper}>
        {actionBar}

        <div className={css.sectionHeaderImagesAspectWrapper}>
          <div
            className={classNames(
              css.sectionHeaderImagesActionImage,
              css.sectionHeaderImagesContainer,
              css.mobileContainer,
            )}
          >
            {renderImage(getImage(listingImagesWithDummy, 0))}
          </div>

          <div
            className={classNames(
              css.sectionHeaderImagesRow,
              css.sectionHeaderImagesContainer,
              css.desktopContainer,
            )}
          >
            <div className={css.sectionHeaderImagesCol1}>
              {renderImage(getImage(listingImagesWithDummy, 0))}
            </div>

            <div className={css.sectionHeaderImagesCol2}>
              <div
                className={classNames(css.sectionHeaderImagesRow, css.sectionHeaderImagesRowSmall)}
              >
                <div className={css.sectionHeaderImagesCol}>
                  {renderImage(getImage(listingImagesWithDummy, 1))}
                </div>
                <div className={css.sectionHeaderImagesCol}>
                  {renderImage(getImage(listingImagesWithDummy, 2))}
                </div>
              </div>

              <div
                className={classNames(css.sectionHeaderImagesRow, css.sectionHeaderImagesRowSmall)}
              >
                <div className={css.sectionHeaderImagesCol}>
                  {renderImage(getImage(listingImagesWithDummy, 3))}
                </div>
                <div className={css.sectionHeaderImagesCol}>
                  {renderImage(getImage(listingImagesWithDummy, 4))}
                </div>
              </div>
            </div>
          </div>

          {viewPhotosButton}
        </div>
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel
          images={listing.images}
          imageVariants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
        />
      </Modal>
    </div>
  );
};

export default SectionHero;
