import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { PriceBox, RFQComponent } from '../../../components';

import css from '../ListingPage.module.css';

const renderPrice = (price, intl) =>
  price
    ? <PriceBox price={price} intl={intl} className={css.rfqPriceSection} />
    : <RFQComponent className={css.rfqPriceSection} />;


const PricesSection = ({ pricePerDay, publicData, intl }) => {
  const weeklyPricePerDay = publicData.weeklyPricePerDay;
  const monthlyPricePerDay = publicData.monthlyPricePerDay;

  const isProductSell = publicData.unitType === "item";

  return (
    <div className={css.pricesSection}>
      <div className={css.pricesWrapper}>
        {isProductSell ? (
          <div>
            <span className={css.pricesLabel}>
              <FormattedMessage id="ListingPage.priceProduct" />
            </span>
            {renderPrice(pricePerDay, intl)}
          </div>
        ) : (
          <>
            <div>
              <span className={css.pricesLabel}>
                <FormattedMessage id="ListingPage.pricePerDayUpperCase" />
              </span>
              {renderPrice(pricePerDay, intl)}
            </div>
            <div>
              <span className={css.pricesLabel}>
                <FormattedMessage id="ListingPage.weeklyPricePerDayUpperCase" />
              </span>
              {renderPrice(weeklyPricePerDay, intl)}
            </div>
            <div>
              <span className={css.pricesLabel}>
                <FormattedMessage id="ListingPage.monthlyPricePerDayUpperCase" />
              </span>
              {renderPrice(monthlyPricePerDay, intl)}
            </div>
          </>
        )}
      </div>
      <p>
        <span className={css.pricesTip}>
          <FormattedMessage id="ListingPage.pricesTip" />
        </span>
      </p>
    </div>
  );
}

export default PricesSection;
