import React from 'react';
import { formatMoney } from '../../../util/currency';
import { FormattedMessage } from '../../../util/reactIntl';
import { types as sdkTypes } from '../../../util/sdkLoader';

import css from '../ListingPage.module.css';

const { Money } = sdkTypes;

const AboutProduct = ({ publicData, intl }) => {
  const perLiterCharge = publicData.perLiterCharge;

  return perLiterCharge ? (
    <div className={css.perLiterChargeContainer}>
      <h2 className={css.perLiterChargeTitle}>
        <FormattedMessage id="ListingPage.perLiterChargeTitle" />
      </h2>
      {perLiterCharge ? <div className={css.perLiterChargeWrapper}>
        <span className={css.perLiterChargeLabel}>
          <FormattedMessage id="ListingPage.fuelPerLiter" />
        </span>
        <span className={css.perLiterCharge}>
          {formatMoney(intl, new Money(perLiterCharge * 100, "EUR"))}
        </span>
      </div> : null}
      <p className={css.aboutThisProduct}>
        <FormattedMessage id="ListingPage.aboutThisProduct" />
      </p>
    </div>
  ) : null;
}

export default AboutProduct;
