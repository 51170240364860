import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import classNames from 'classnames';

import css from '../ListingPage.module.css';

const LeftArrowIcon = () => {
  return (
    <svg className={css.arrowIcon} width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.27116 0.75L0.291992 4.27083L3.00033 7.41927H5.16699L2.18783 4.27083L5.16699 0.75H3.27116Z" fill="#4871F7"/>
    </svg>
  )
}

const RightArrowIcon = () => {
  return (
    <svg className={css.arrowIcon} width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.72884 7.25L5.70801 3.72917L2.99967 0.580729L0.833008 0.580729L3.81217 3.72917L0.833008 7.25L2.72884 7.25Z" fill="#4871F7"/>
    </svg>
  )
}

const Carousel = ({items}) => {
  return (
    <AliceCarousel
      mouseTracking
      items={items}
      disableDotsControls={true}
      renderPrevButton={() => {
        return (
          <div className={classNames(css.sliderBtn, css.sliderBtnLeft)}>
            <LeftArrowIcon />
          </div>
        )
      }}
      renderNextButton={() => {
        return (
          <div className={classNames(css.sliderBtn, css.sliderBtnRight)}>
            <RightArrowIcon />
          </div>
        )
      }}
    />
  );
};

export default Carousel;
