import React, { useState } from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { PriceBox, RFQComponent } from '../../../components';

import { useMediaQuery } from '../ListingPage.shared';

import css from './AttachmentsSection.module.css';

const LeftArrowIcon = () => {
  return (
    <svg className={css.icon} width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.27116 0.75L0.291992 4.27083L3.00033 7.41927H5.16699L2.18783 4.27083L5.16699 0.75H3.27116Z" fill="#4871F7"/>
    </svg>
  )
}

const RightArrowIcon = () => {
  return (
    <svg className={css.icon} width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.72884 7.25L5.70801 3.72917L2.99967 0.580729L0.833008 0.580729L3.81217 3.72917L0.833008 7.25L2.72884 7.25Z" fill="#4871F7"/>
    </svg>
  )
}

export const Slider = ({ cards }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextBox = () => {
    setCurrentIndex((currentIndex + 1) % cards.length);
  };

  const prevBox = () => {
    setCurrentIndex((currentIndex - 1 + cards.length) % cards.length);
  };

  return (
    <div className={css.slider}>
      <div className={css.sliderContainer}>
        {cards[currentIndex]}
      </div>
      <div className={css.sliderControls}>
        <button onClick={prevBox} className={css.sliderBtn}>
          <LeftArrowIcon />
        </button>
        <button onClick={nextBox} className={css.sliderBtn}>
          <RightArrowIcon />
        </button>
      </div>
    </div>
  );
};

const renderPrice = (price, intl) =>
  price
    ? <PriceBox price={price} intl={intl} className={css.price} />
    : <RFQComponent className={css.rfq} />;

const Card = ({ attachment, intl }) => {

  if (!attachment) return null;

  const {
    isPricing,
    paidTypeAttachment,
    freeTypeAttachment,
    paidTypeAttachmentDescription,
    freeTypeAttachmentDescription,
    priceMonthlyPerDay,
    pricePerDay,
    priceWeeklyPerDay,
  } = attachment;
  const isPaid = typeof isPricing !== "undefined";

  return (
    <div className={css.cardContainer}>
      <h4 className={css.cardTitle}>{isPaid ? paidTypeAttachment : freeTypeAttachment}</h4>
      <p className={css.cardDescription}>{isPaid ? paidTypeAttachmentDescription : freeTypeAttachmentDescription}</p>
      <div className={css.cardBody}>
        {isPaid ? (
          <>
            <div className={css.priceRow}>
              {renderPrice(pricePerDay, intl)}
              <span className={css.priceLabel}>
                <FormattedMessage id="ListingPage.pricePerDay" />
              </span>
            </div>
            <div className={css.priceRow}>
              {renderPrice(priceWeeklyPerDay, intl)}
              <span className={css.priceLabel}>
                <FormattedMessage id="ListingPage.priceWeeklyPerDay" />
              </span>
            </div>
            <div className={css.priceRow}>
              {renderPrice(priceMonthlyPerDay, intl)}
              <span className={css.priceLabel}>
                <FormattedMessage id="ListingPage.priceMonthlyPerDay" />
              </span>
            </div>
          </>
        ) : (
          <span className={css.priceIncluded}>
            <FormattedMessage id="ListingPage.priceIncluded" />
          </span>
        )}
      </div>
    </div>
  )
}

const AttachmentsSection = ({ publicData = {}, intl }) => {
  const matches = useMediaQuery('(max-width: 668px)');

  const { freeAttachments = [], paidAttachments = [] } = publicData;
  const totalAttachments = freeAttachments.concat(paidAttachments);

  const attachments = matches
    ? totalAttachments.map((attach, i)=> (
      <div key={i} className={css.cardsWrapper}>
        <Card attachment={attach} intl={intl} />
      </div>
    )) : totalAttachments.map((_, index) => {
      return index % 2 === 0 ? (
        <div key={index} className={css.cardsWrapper}>
          <Card attachment={totalAttachments[index]} intl={intl} />
          <Card attachment={totalAttachments[index + 1]} intl={intl} />
        </div>
      ) : null;
    }).filter(Boolean);

  return attachments.length ? (
    <div>
      <h2 className={css.attachmentsHeadline}>
        <FormattedMessage id="ListingPage.attachmentsSectionTitle" />
      </h2>

      <Slider cards={attachments} />
    </div>
  ) : null;
}

export default AttachmentsSection;
