import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { RFQComponent, PriceBox, ResponsiveImage, AspectRatioWrapper, NamedLink } from '../../../components';
import { lazyLoadWithDimensions } from '../../../util/uiHelpers';
import { createSlug } from '../../../util/urlHelpers';

import css from '../ListingPage.module.css';

const cardRenderSizes = () => {
    return [
      '(max-width: 549px) 100vw',
      '(max-width: 767px) 50vw',
      `(max-width: 1439px) 26vw`,
      `(max-width: 1920px) 18vw`,
      `14vw`,
    ].join(', ');
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const renderPrice = (price, intl) =>
  price
    ? <PriceBox price={price} intl={intl} className={css.relatedListingPrice} />
    : <RFQComponent className={css.relatedListingPrice} />;

const RelatedListings = ({ relatedListings, intl, config }) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;

  return relatedListings.length ? (
    <div className={css.relatedListingsRoot}>
      <h2 className={css.relatedListingsTitle}>
        <FormattedMessage id="ListingPage.relatedListingsTitle" />
      </h2>
      <p className={css.relatedListingsInfo}>
        <FormattedMessage id="ListingPage.relatedListingsInfo" />
      </p>
      <div className={css.relatedListingsSection}>
        {relatedListings.slice(0, 3).map(({ attributes, images = [], id }, index) => {
          const pricePerDay = attributes?.price;
          const {
            weeklyPricePerDay,
            monthlyPricePerDay,
          } = attributes?.publicData ?? {};

          const firstImage = images.length > 0 ? images[0] : null;
          const variants = firstImage
            ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
            : [];

          const slug = createSlug(attributes.title);

          return (
            <NamedLink key={index} className={css.relatedListing} name="ListingPage" params={{ id: id.uuid, slug }}>
              <div>
                <AspectRatioWrapper
                  className={css.aspectRatioWrapper}
                  width={aspectWidth}
                  height={aspectHeight}
                >
                  <LazyImage
                    rootClassName={css.rootForImage}
                    alt={attributes.title}
                    image={firstImage}
                    variants={variants}
                    sizes={cardRenderSizes()}
                  />
                </AspectRatioWrapper>
              </div>
              <div className={css.relatedListingInfo}>
                <h4 className={css.relatedListingDescription}>
                  {attributes.title}
                </h4>

                <div className={css.relatedListingsPricesWrapper}>
                  <div className={css.relatedListingPriceWrapper}>
                    <span className={css.relatedListingPricesLabel}>
                      <FormattedMessage id="ListingPage.pricePerDayUpperCase" />
                    </span>
                    {renderPrice(pricePerDay, intl)}
                  </div>
                  <div className={css.relatedListingPriceWrapper}>
                    <span className={css.relatedListingPricesLabel}>
                      <FormattedMessage id="ListingPage.weeklyPricePerDayUpperCase" />
                    </span>
                    {renderPrice(weeklyPricePerDay, intl)}
                  </div>
                  <div className={css.relatedListingPriceWrapper}>
                    <span className={css.relatedListingPricesLabel}>
                      <FormattedMessage id="ListingPage.monthlyPricePerDayUpperCase" />
                    </span>
                    {renderPrice(monthlyPricePerDay, intl)}
                  </div>
                </div>
              </div>
            </NamedLink>
          )
        })}
      </div>
    </div>
  ) : null
}

export default RelatedListings;
